import { WagmiConfig, createConfig, configureChains  } from 'wagmi'
import { base, baseGoerli } from 'wagmi/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import {useEffect} from 'react'
import { Buffer } from "buffer";

import Navbar from "./components/Navbar";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

//import { infuraProvider } from 'wagmi/providers/infura'
//import { publicProvider } from 'wagmi/providers/public'
//import { InjectedConnector } from 'wagmi/connectors/injected'

import GlobalStyle from './components/GlobalStyle'
import Index from './components/Index'
import WhitelistPage from './components/WhitelistPage'
import TokenViewerPage from './components/TokenViewerPage'
import MerklePage from './components/MerklePage'

window.Buffer = window.Buffer || Buffer;
 
 const { publicClient, webSocketPublicClient  } = configureChains(
  [base, baseGoerli],
  [
    alchemyProvider({ apiKey: 'k5u1H1zQC3iHJZJGTUzZeUpw11HbzT0L' }),
    //publicProvider(),
  ],
)

const config = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient
})

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index/>,
  },

  {
    path: "/whitelist",
    element: <WhitelistPage/>,
  },

  {
    path: "/collection",
    element: <TokenViewerPage/>,
  },

   {
    path: "/merkle",
    element: <MerklePage/>,
  },
]);

function App() {

  return (
    <WagmiConfig config={config}>
      
        <GlobalStyle />


        <RouterProvider router={router} />

        {/*<Switch>
          <Route path="/whitelist">
            <WhitelistPage />
          </Route>
          <Route path="/">
            <Index />
          </Route>
        </Switch>*/}


    </WagmiConfig>
  )
}

export default App;