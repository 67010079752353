import styled from 'styled-components';
import * as Style from "./StyledComponents";
import Navbar from "./Navbar";
import { useState } from 'react';
import inscriptionJson from "../json/inscriptions.json";

import imgIconMagicEden from "../images/viewer/magiceden.png"
import imgIconOKX from "../images/viewer/okx.png"
import imgIconOrdio from "../images/viewer/ordio.png"
import imgIconGamma from "../images/viewer/gamma.png"

// Dynamically import images
const loadImage = (tokenId) => {
  try {
    return require(`../images/tokens/${tokenId}.png`);
  } catch (e) {
    // If the image is not found, return a default image or handle the error
    return require('../images/qvga.png');
  }
};


export const ColumnWide = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 10px;
  flex: 1;
  /*background-color: red;*/

  @media (max-width: 765px) {
    margin: 0;
  }

  @media (max-width: 650px) {
    justify-content: center; // Center the columns if they are less than the container's width
    margin: 0;
  }
`;

export const TokenContainer = styled.div`
  width: 320px;
  margin-right: 30px;
  margin-bottom: 30px;

  img {
    width: 100%;
    height: auto;
    image-rendering: auto;
    margin: 0;
    padding: 0;

  }

  @media (max-width: 650px) {
    margin: 20px 0;
  }
`;


export const TokenDesc = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
  margin-top: 2px;
  padding: 3px;
  border-radius: 4px;

  a {
    margin-left: 5px;
    position: relative;
    top: 2px;

    &:hover {
      position: relative;
      top: 3px;
      left: 1px;
    }
  }

  img {
    image-rendering: auto;
    width: 16px;
    height: 16px;
  }
`;

export const TokenId = styled.div`
  font-weight: bold;
  margin-left: 5px;
`;

export const TokenLinks = styled.div`
  // Add any additional styling for TokenLinks here
`;


const TokenSingle = ({data}) => {

  const imageSrc = loadImage(data.tokenId);

  return (
    <TokenContainer>
      <img src={imageSrc} alt={`Token ${data.tokenId}`} width="320" height="240" />
     
      <TokenDesc>
        <TokenId>#{data.tokenId}</TokenId>
        <TokenLinks>
           <a rel="noreferrer"  href={`https://magiceden.io/ordinals/item-details/${data.id}`} target="_blank"><img src={imgIconMagicEden} alt={`Magic Eden`} width="320" height="240" /></a>
           <a rel="noreferrer"  href={`https://www.okx.com/web3/marketplace/nft/asset/btc/${data.id}`} target="_blank"><img src={imgIconOKX} alt={`OKX`} width="320" height="240" /></a>
           <a rel="noreferrer"  href={`https://gamma.io/inscription/${data.id}`} target="_blank"><img src={imgIconGamma} alt={`Gamma`} width="320" height="240" /></a>
           <a rel="noreferrer"  href={`https://www.ord.io/${data.id}`} target="_blank"><img src={imgIconOrdio} alt={`Ord`} width="320" height="240" /></a>
     

        </TokenLinks>
        {/*<p>Traits {JSON.stringify(data.traits)}</p>*/}
      </TokenDesc>
    </TokenContainer>
  );
}

const ViewerTable = ({ filteredData }) => {


  return (
    <ColumnWide>
      {filteredData.map(tokenData => (
        <TokenSingle key={tokenData.tokenId} data={tokenData}/>
      ))}
    </ColumnWide>
  );
}

const Dropdown = ({ options, selectedValue, onChange }) => {
  return (
    <Style.DropdownContainer>
      <Style.DropdownSelect value={selectedValue} onChange={(e) => onChange(e.target.value)}>
        <Style.DropdownOption value="All">All</Style.DropdownOption>
        {options.map(option => (
          <Style.DropdownOption key={option} value={option}>{option}</Style.DropdownOption>
        ))}
      </Style.DropdownSelect>
    </Style.DropdownContainer>
  );
};

const TraitSelector = ({ title, options, selectedValue, onSelectionChange }) => {
  return (
    <>
      <br/>
      <p><strong>{title}:</strong></p>
      <Dropdown
        options={options}
        selectedValue={selectedValue}
        onChange={onSelectionChange}
      />
    </>
  );
}

const FilterBox = ({ onFilterChange, filters, filteredData }) => {

  const fileUrl = process.env.PUBLIC_URL + "/json/inscriptions.json";

  return (
    <Style.Window>
      <Style.WindowTitleBar>
        <Style.WindowTitle>filter.exe</Style.WindowTitle>
        <Style.Rectangle />
      </Style.WindowTitleBar>

      <Style.WindowContent>
          <strong><Style.IconLogo/> Memory Blocks</strong>
          <Style.Break/>
          <p><em>Memory Blocks is a generative art collection that explores the remnants of our digital past, when early operating systems were a frontier of discovery and wonder.</em></p>
          <Style.Break/>
          {Object.entries(inscriptionJson.traits).filter(([trait]) => trait !== "Hash").map(([trait, options]) => (
            <TraitSelector
              key={trait}
              title={trait}
              options={options}
              selectedValue={filters[trait]}
              onSelectionChange={(value) => onFilterChange(trait, value)}
            />
          ))}
          <Style.Break/>

          <div style={{}}><strong>{filteredData.length} results</strong></div>
          <Style.Break/>

          (<a href={fileUrl} download="inscriptions.json">Download JSON Metadata</a>)
      </Style.WindowContent>
    </Style.Window>
  );
}

function TokenViewerPage() {
  const [filters, setFilters] = useState({
    Window: 'All',
    Background: 'All',
    Container: 'All',
    'Container Fill': 'All',
    'Container Stroke': 'All'
  });

  const handleFilterChange = (trait, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [trait]: value
    }));
  }

  const filteredData = inscriptionJson.inscriptions.filter(token => 
    Object.entries(filters).every(([key, value]) => 
      value === 'All' || token.traits[key] === value
    )
  );


  return (
    <Style.Background>
      <Navbar/>
      <Style.ContainerViewer>
        <Style.ColumnFilter>
          <FilterBox onFilterChange={handleFilterChange} filters={filters} filteredData={filteredData}/>
        </Style.ColumnFilter>
        <ViewerTable filteredData={filteredData}/>
      </Style.ContainerViewer>
    </Style.Background>
  );
}

export default TokenViewerPage;
