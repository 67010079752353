//import { base, baseGoerli } from 'wagmi/chains'

const hidden = false;
/*
//const NETWORK = "base";
const NETWORK = "baseGoerli";

const contractABI = require("./abi/MemoryBlocks.json");

const CONTRACT_ADDRESS_BASE = `0x61e62b41b8d6e3239710f195329a19ecc04c4713`;
const CONTRACT_ADDRESS_BASE_GOERLI = `0x61e62b41b8d6e3239710f195329a19ecc04c4713`;


const getTxnUrl = (hash, chainId)=> {
  switch(chainId) {
    case 8453:
      return `https://basescan.org/tx/${hash}`;
    case 84531:
      return `https://goerli.basescan.org/tx/${hash}`;
    default:
      return `https://etherscan.io/tx/${hash}`;
  }
}

const getAccountUrl = (hash, chainId)=> {
  switch(chainId) {
    case 8453:
      return `https://basescan.org/address/${hash}`;
    case 84531:
      return `https://goerli.basescan.org/address/${hash}`;
    default:
      return `https://etherscan.io/address/${hash}`;
  }
}

const currNetwork = NETWORK==="base" ? base: baseGoerli;
const contractAddress = NETWORK==="base" ? CONTRACT_ADDRESS_BASE : CONTRACT_ADDRESS_BASE_GOERLI;

const network = {
  chain: currNetwork,
  getTxnUrl: (hash)=>{return getTxnUrl(hash, currNetwork.id);},
  getAccountUrl: (hash)=>{return getAccountUrl(hash, currNetwork.id);},
};

const contract = {
  abi: contractABI.abi,
  address : contractAddress,
  etherscan  : `${network.getAccountUrl(contractAddress)}#code`
}
*/

const links = {
  twitter: "https://twitter.com/dailofrog",
  view: "https://twitter.com/dailofrog",
  linktree: "https://linktr.ee/dailofrog",


  endGame: "https://www.endgamebase.xyz/",
  communityGardens: "https://www.communitygardens.art/",


  // collections
  magicEden: "https://magiceden.io/ordinals/marketplace/memoryblocks",
  gammaIO: "https://gamma.io/ordinals/collections/memory-blocks",
  ordinalsWallet: "https://ordinalswallet.com/",
  ordinalsMarket: "https://ordinals.market/",
  okx: "https://www.okx.com/web3/marketplace/nft/collection/btc/memory-blocks",
  inscribeNow: "https://inscribenow.io/collections/9d732dcfbce8ddbd",

  docsMetadata: "https://docs.ordinals.com/inscriptions/metadata.html",

  inscriptionArtist: "https://ordiscan.com/inscription/56620864",
  inscriptionParent: "https://ordiscan.com/inscription/56637155",
  inscriptionRenderer: "https://ordiscan.com/inscription/56817241",
  inscriptionStylesheet: "https://ordiscan.com/inscription/56817242",
};

const Ahref = ({href, value})=> {
  return (
    <a rel="noreferrer" href={links[href]} target="_blank">{value}</a>
  )
}


export {
  links, 
  //contract, 
  //network,
  hidden,
  Ahref,
};
