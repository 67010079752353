import * as Style from "./StyledComponents";
import {Ahref, links} from "../Config";
import styled from 'styled-components';

import { Link } from "react-router-dom";

import imgQVGA from "../images/qvga.jpg";

export const FooterMsg = styled.div`
  text-align:center;
  text-decoration:italics;
  padding: 0.5em 0;
`;

function FAQ() {
  return (

      <Style.Window>
        <Style.WindowTitleBar>
          <Style.WindowTitle>faq.txt</Style.WindowTitle>
          <Style.Rectangle />
        </Style.WindowTitleBar>

        <Style.WindowContent>

          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> Is the artwork fully onchain?</Style.FaqHeader>
            <Style.FaqContent>
              <p>Yes. <em>Memory Blocks</em> collection is <strong>100% fully onchain</strong> on the Bitcoin network, including the metadata and the recursive modules utilized in constructing the artwork.</p>
              <p>This means there is no reliance on third party hosting solutions (i.e. IPFS and Arweave). Everything required to construct the artwork is stored onchain and procedurally generated on-demand as a lossless SVG-embedded HTML via <Ahref href="inscriptionRenderer" value="recursive modules"/>. This artwork will survive forever as long as Bitcoin persists.</p>
              <p>There are also no external graphics library used (i.e. threejs and p5js) in the renderer, just simple old-fashioned procedurally generative SVG, pure and simple.</p>
              <p><em><strong>We truly believe in the fully-onchain, decentralized, permissionless future of computer art.</strong></em></p>
            </Style.FaqContent>
          </Style.FaqItem>

          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> Who is the creator?</Style.FaqHeader>
            <Style.FaqContent>
            <p><em>Memory Blocks</em> is created by <Ahref href="twitter" value="dailofrog"/> -- a creative technologist focusing on generative art and onchain interactive experiences.</p>
            <p>Previously, dailofrog released <a target="_blank" href="https://www.tinybasedfrogs.xyz/">Tiny Based Frogs</a>, which is Base's first 100% fully onchain NFT collection and currently the top-traded Base collection on OpenSea. They have also released <Ahref href="endGame" value="The End Game"/> and, most recently, <Ahref href="communityGardens" value="Community Gardens"/>.</p>
            <p>View dailofrog's previous <Ahref href="linktree" value="projects here"/>.</p></Style.FaqContent>
          </Style.FaqItem>

          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> What's the image type?</Style.FaqHeader>
            <Style.FaqContent>
              <p>Each <em>Memory Block</em> digital artifact is rendered on-demand and algorithmically constructed from <Ahref href="inscriptionRenderer" value="recursive modules"/> to be outputted as a SVG-embedded HTML. This vectorized SVG format allows the image to be perfectly lossless with no image compression artifacts.</p>
            </Style.FaqContent>
          </Style.FaqItem>

          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> Why 320x240 resolution?</Style.FaqHeader>
            <Style.FaqContent>
              <p>The resolution of 320x240 pixels, commonly known as "QVGA" (Quarter Video Graphics Array), holds a notable place in the history of computer graphics and video technology. Emerging in the late 1980s and early 1990s, this resolution became a standard for early home computers and video game consoles. We pay homage to this classic format with <em>Memory Blocks</em>.</p>

              <div style={{ textAlign: "center", marginTop:"10px", width:"auto" }}>
                <img
                  style={{ maxWidth: "100%", height:"auto" }}
                  src={imgQVGA}
                />
                <em>(Rendered on QVGA display module)</em>
              </div>
            </Style.FaqContent>
          </Style.FaqItem>

          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> Why does the pattern look slightly different on certain storefronts?</Style.FaqHeader>
            <Style.FaqContent>
              <p>Lines and dithered patterns are intended to be exactly 1-pixel wide with anti-aliasing disabled to emulate the pixelated aesthetics of early operating systems.</p>
              <p>During research, we have investigated various render methods (i.e. p5js, canvas, etc) and found that pure SVG yielded the best visual results. However, note that some operating systems and browsers may attempt to render the SVG in non-standard ways which may cause subpixel artifacts in collapsed lines.</p>
              <p>Certain storefronts may also opt-in to pre-rasterize the SVG, which unfortunately causes moire effects when the image is resized (i.e. interference patterns on collapsed pixels). That said, since this project is stored entirely onchain, you can reconstruct the raw artwork at anytime via recursive modules.</p>
            </Style.FaqContent>
          </Style.FaqItem>


          <Style.FaqItem>
            <Style.FaqHeader><Style.IconLogo/> Roadmap?</Style.FaqHeader>
            <Style.FaqContent><p>No roadmap, no utility. <em><strong>Art for the sake of art.</strong></em></p>
            <p>We view <em>Memory Blocks</em> as our genesis Ordinals collection and are excited to continue exploring within this technology.</p>
            </Style.FaqContent>
          </Style.FaqItem>

          <FooterMsg>🐸🚬<br/><Ahref href="twitter" value="@dailofrog"/></FooterMsg>
        </Style.WindowContent>
      </Style.Window>

  );
}

export default FAQ;
