import styled from 'styled-components';
import { ethers } from 'ethers';

import * as Style from "./StyledComponents";
import {links, contract, network, hidden} from "../Config";
import Navbar from "./Navbar";

import {useState, useEffect} from 'react'
import {containsAddressInList} from '../merkle/use-merkle'

function isValidEthereumAddress(address) {
    try {
        return ethers.isAddress(address);
    } catch (error) {
        return false;
    }
}

function checkWhitelisted(address) {
    try {
        return containsAddressInList(address);
    } catch (error) {
        return false;
    }
}

function Box() {

  const [walletAddress, setWalletAddress] = useState("");
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [isValidEthWallet, setIsValidEthWallet] = useState(false);

  function handleTextInput(event) {
    const newWalletAddress = event.target.value.toLowerCase();
    setWalletAddress(newWalletAddress);
  }

  useEffect(()=>{
    if (walletAddress !== ""){
      setIsValidEthWallet(isValidEthereumAddress(walletAddress));
      setIsWhitelisted(checkWhitelisted(walletAddress));
    } else {
      setIsValidEthWallet(false);
      setIsWhitelisted(false);
    }

  }, [walletAddress]);

  return (
    <Style.Window>
        <Style.WindowTitleBar>
          <Style.WindowTitle>WHITELIST.exe</Style.WindowTitle>
          <Style.Rectangle />
        </Style.WindowTitleBar>

        <Style.WindowContent>
            <strong><Style.IconLogo/> Whitelist Checker</strong>
            <Style.Break/>
            <p>Check whitelist elligibiliy by typing in your full ETH wallet address below.</p>
            <Style.Break/>
            <p><strong>* Do NOT use any ENS names (like "name.eth")</strong></p>
            <Style.Break/>
            <Style.Break/>
                <p><strong>ETH Wallet Address:</strong></p>
               <Style.TextInput type="text" value={walletAddress} onChange={handleTextInput} placeholder="(example: 0xfffff...)" />
            <Style.Break/>

            {isWhitelisted && <Style.TransactionSuccessMsg>'{walletAddress}' is whitelisted! ✔️</Style.TransactionSuccessMsg>}
            {isValidEthWallet  && !isWhitelisted && walletAddress !== "" &&  <Style.TransactionErrorMsg>'{walletAddress}' is not whitelisted! ❌ </Style.TransactionErrorMsg>}
            {!isValidEthWallet && walletAddress !== "" &&  <Style.TransactionErrorMsg>'{walletAddress}' is not a valid wallet address! ❌ </Style.TransactionErrorMsg>}
        </Style.WindowContent>
    </Style.Window>
  )
}

function WhitelistPage() {
  return (
    <Style.Background>
      <Navbar/>
      <Style.Container>

        <Style.ColumnWide>
          <Box/>
        </Style.ColumnWide>

      </Style.Container>
    </Style.Background>
  );
}

export default WhitelistPage;
