// src/StyledComponents.js
import styled from 'styled-components';

import { Link } from "react-router-dom";

import backgroundImage from '../images/bg.png'; // Replace with the path to your image


export const UnicodeIcon = styled.span`
  font-family:inherit;
`;

export const IconLogo = ()=>{
  return (
      <UnicodeIcon>▣</UnicodeIcon>
  )
};

export const IconSquare = ()=>{
  return (
      <UnicodeIcon>▪</UnicodeIcon>
  )
};


export const Background = styled.div`
  /*background-color:#bbb;*/
  background-image: url(${backgroundImage});
  background-repeat: repeat;
  background-attachment: fixed; // This will fix the background position while scrolling
  width: 100%;
  min-height: 100vh;
  height: auto;
`;

export const Divider = styled.hr`
  margin: 10px 0;
  border-top: 1px dotted black;
  height: 0;
`;

export const Navbar = styled.nav`
  background-color: black;
  color: white;
  border-bottom: 1px solid white;
  padding: 8px 0 7px 0;
  margin-bottom: 20px;

  display: flex; // Enable Flexbox
  justify-content: space-between; // Space out children elements
  align-items: center; // Align children vertically in the center

  color: gray;
  a {
    font-weight: normal;
    color: white;
    text-decoration: none;
    margin-right: 1em;
  }

  a:hover {
    text-decoration: underline;
  }

  @media (max-width: 650px) {
    justify-content: center; // Center the columns if they are less than the container's width
    //display:none;
  }
`;

export const NavbarIcon = styled.span`
  margin: 0 20px 0 20px;
`;


export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; // Center the columns if they are less than the container's width
`;

export const ContainerViewer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;

  @media (max-width: 650px) {
    justify-content: center; // Center the columns if they are less than the container's width
    //display:none;
  }

`;


export const Break = styled.div`
  height: 1em;
`;


export const WindowImage = styled.img`
  width: 100%;
  margin: 0 0 8px 0;
  padding: 0;
`;

export const Window = styled.div`
  background-color: black;
  border: 1px solid white;
  position: relative;
  margin-bottom: 10px;

  z-index: 0;

  p {
    margin: 0;
    padding: 0;
  }
`;

export const Rectangle = styled.div`
  width: 13px;
  height: 13px;
  border: 1px solid white;
  position: absolute;
  right: 2px; // Right aligned
`;

export const WindowContent = styled.div`
  background: white;
  align-items: center;
  justify-content: center;

  border: 1px solid black;
  margin: 0 2px 2px 2px;
  padding: 10px;
`;

export const WindowTitleBar = styled.div`
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 0 2px 0;
`;

export const WindowTitle = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: white;
`;

export const ColumnLeft = styled.div`
  width: 320px;
  padding: 0px;
  margin: 0 7px;
`;

export const ColumnWide = styled.div`
  width: 640px;
  padding: 0px;
  margin: 0 7px;
`;


export const ColumnRight = styled.div`
  width: 400px;
  min-width:320px;
  padding: 0px;
  margin: 0 7px;

  @media (max-width: 765px) {
    width: 320px;
  }
`;

export const HiddenMobile = styled.span`
  @media (max-width: 650px) {
    display:none;
  }
`;

export const ViewOnlyMobile = styled.span`
display:none;
  @media (max-width: 650px) {
    display:inline;
  }
`;

export const NavbarLinks = styled.span`
  @media (max-width: 650px) {
    text-align:center;
    display:block;
  }
`;

export const ColumnFilter = styled.div`
  width: 240px;
  padding: 0px;
  margin: 0 7px;

  @media (max-width: 650px) {
    width: 320px;
    margin: 0;
  }

`;

export const FaqItem = styled.div`
border-bottom: 1px dotted black;
  padding-top:5px;
  padding-bottom:5px;
  margin-bottom:10px;
`;

export const FaqHeader = styled.h1`
  margin-bottom: 5px;
`;

export const FaqContent = styled.div`
  p {
    margin-bottom:1em;
  }
`;

export const TextInput = styled.input`
  padding: 3px 10px;
  border: 1px solid #ccc;
  transition: border-color 0.2s ease;
  width: 100%;
  box-sizing: border-box;

  background-color: ${({ disabled }) => (disabled ? '#ccc' : 'white')};

  &:focus {
    border-color: #0077b6;
    outline: none;
    background-color:#ffffcc;
  }
`;

export const Button = styled.button`
  padding: 3px 10px;
  margin-right: 2px;
  font-weight: bold;

  background-color: ${({ disabled }) => (disabled ? '#ccc' : 'yellow')};
  color:${({ disabled }) => (disabled ? 'grey' : 'black')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  // To remove any rounding, set the border-radius to 0
  //border-radius: 0;
  border: ${({ disabled }) => (disabled ? '1px solid grey' : '1px solid black')};

  &:active {
    background-color: ${({ disabled }) => (disabled ? '#ccc' : 'blue')};
    color: ${({ disabled }) => (disabled ? 'grey' : 'white')};
  }
`;

export const TransactionErrorMsg = styled.div`
  background-color:red;
  color: yellow;
  padding: 5px;
  margin: 5px 0;
  word-wrap: break-word;
  white-space: normal;
  border: 1px solid black;
`;

export const TransactionSuccessMsg = styled.div`
  background-color:#00FF00;
  color: black;
  padding: 5px;
  margin: 5px 0;
  word-wrap: break-word;
  white-space: normal;
  border: 1px solid black;
`;

export const Footer = styled.div`
  background-color:black;
  color: white;
  border-top: 1px solid white;
  padding: 6px 0 7px 0;
  text-align:center;
  margin-top: 10px;
`;

export const ViewCollectionDiv = styled.div`
 a {
  background-color: yellow;
  color: black;
  display:block;
  text-align:center;
  padding: 7px;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  text-decoration:none;
  font-weight:bold;

  &:hover {
    background-color:magenta;
    color:white;
  }

  &:active {
    background-color:black;
    color:white;

    border: 0;
    border-left: 1px solid black;
    border-top: 1px solid black;
  }
 }
`;


export const MintButton = styled.a`
  background-color: #00a854;
  color: yellow;
  display:block;
  text-align:center;
  padding: 7px;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  text-decoration:none;
  font-weight:bold;

  &:hover {
    background-color: yellow;
    color:black;
  }

  &:active {
    background-color:green;
    color:black;

    border: 0;
    border-left: 1px solid black;
    border-top: 1px solid black;
  }
`;


export const DropdownContainer = styled.div`
  width: 100%;
`;

export const DropdownSelect = styled.select`
  width: 100%;
  padding: 5px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

export const DropdownOption = styled.option``;