import * as Style from "./StyledComponents";
import {links} from "../Config";

import { Outlet, Link } from "react-router-dom";


function Navbar() {
  return (
    <Style.Navbar>

        <Style.HiddenMobile>
          <Style.NavbarIcon><Link to={`/`}><Style.IconLogo/> <strong>Memory Blocks</strong></Link></Style.NavbarIcon>
        </Style.HiddenMobile>

        <Style.NavbarLinks>

         <Style.ViewOnlyMobile>
          <Style.NavbarIcon><Link to={`/`}><Style.IconLogo/></Link></Style.NavbarIcon>
        </Style.ViewOnlyMobile>
        
        {"/ "}<Link to={`/collection/`}>Collection</Link>
        {"/ "}<a rel="noreferrer" target="_blank" href={links.magicEden}>Magic Eden</a>
        {"/ "}<a rel="noreferrer" target="_blank" href={links.twitter}>Twitter</a>
        </Style.NavbarLinks>

      </Style.Navbar>
    )
}


export default Navbar;
