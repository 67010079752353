import * as Style from "./StyledComponents";
import FAQ from "./FAQ";
import Navbar from "./Navbar";
import {links, Ahref} from "../Config";
import { Link } from "react-router-dom";

import imgExample1 from "../images/examples-new/1.png";
import imgExample2 from "../images/examples-new/2.png";
import imgExample3 from "../images/examples-new/3.png";
import imgExample4 from "../images/examples-new/4.png";
import imgExample5 from "../images/examples-new/5.png";
import imgExample6 from "../images/examples-new/6.png";
import imgExample7 from "../images/examples-new/7.png";
import imgExample8 from "../images/examples-new/8.png";
import imgExample9 from "../images/examples-new/9.png";
import imgExample10 from "../images/examples-new/10.png";
import imgExample11 from "../images/examples-new/11.png";
import imgExample12 from "../images/examples-new/12.png";
import imgExample13 from "../images/examples-new/13.png";
import imgExample14 from "../images/examples-new/14.png";
import imgExample15 from "../images/examples-new/15.png";
import imgExample16 from "../images/examples-new/16.png";
import imgExample17 from "../images/examples-new/17.png";
import imgExample18 from "../images/examples-new/18.png";
import imgExample19 from "../images/examples-new/19.png";
import imgExample20 from "../images/examples-new/20.png";
import imgExample21 from "../images/examples-new/21.png";
import imgExample22 from "../images/examples-new/22.png";
import imgExample23 from "../images/examples-new/23.png";
import imgExample24 from "../images/examples-new/24.png";
import imgExample25 from "../images/examples-new/25.png";
import imgExample26 from "../images/examples-new/26.png";
import imgExample27 from "../images/examples-new/27.png";
import imgExample28 from "../images/examples-new/28.png";
import imgExample29 from "../images/examples-new/29.png";

import imgTowers from "../images/towers.png";
//import imgStacks from "../images/stack.png";



const imageExamples = [
/*imgExample1,
imgExample17,
imgExample14,
imgExample6,
imgExample13,
imgExample3,*/

imgExample22,
imgExample19,
imgExample21,
imgExample29,
imgExample23,
imgExample20,
]


/*function Mint() {
  return (
      <>
        <MintWindow />
      </>
    )
}*/

function Statement() {
  return (
    <div style={{textAlign:"center"}}>
      <p>
        {" "}
        <Style.IconLogo /> <strong>Artist Statement</strong>
      </p>

      <Style.Break />

      <p>
        <em>Memory Blocks</em> weaves together prismatic blocks of bygone
        operating systems, inscribing them on to the blockchain's immutable
        canvas.
      </p>
      <Style.Break />
      <p>
        Each <em>Memory Block</em> reverberates with the echoes of a digital
        past, bridging the gap between nascent computer memories once ephemeral and now etched
        eternally onchain.
      </p>
      <Style.Break />

               <div style={{ textAlign: "center", marginTop:"10px" }}>
                <img
                  style={{ marginBottom: "-13px", padding: "0" }}
                  src={imgTowers}
                />
              </div>
    </div>
  );
}


function MintInfo() {
  const maxSupply = 128;
  const price = 0.005;
  const inscriptionBytes = 435;
  //const publicMintDate = "Soon";
  const publicMintDate = "Jan 25 (11am PST)";
  const whitelistMintDate = "Jan 25 (9am PST)";

  const fileUrl = process.env.PUBLIC_URL + "/json/inscriptions.json";


  return (
    <>
      <p>
        <strong>✔️ Minted Out</strong> (<Ahref href="inscribeNow" value="InscribeNow.io"/>)
        <br />
        <br />
        <Style.IconSquare /> <strong>Supply</strong>: {maxSupply}
        <br />
        <Style.IconSquare /> <strong>Price</strong>: { `${price} BTC`}
        <br />
        <Style.IconSquare /> <strong>Release Date</strong>: January 25, 2024
        <br />
        <Style.IconSquare /> <strong>Type</strong>: Ordinals (BTC)
        <br />
        <Style.IconSquare /> <strong>Onchain</strong>: 100% (via <Ahref href="inscriptionRenderer" value="Recursive Modules"/>)
        <br />
        <Style.IconSquare /> <strong>Visual</strong>: Generative SVG-Embedded HTML
        <br />
        <Style.IconSquare /> <strong>Image Size</strong>: 320x240 (QVGA)
        <br />
        <Style.IconSquare /> <strong>Inscription Size</strong>:{" "}
        {inscriptionBytes} bytes (~546 sats)
        <br />
        <Style.IconSquare /> <strong>Metadata</strong>:{" "}
        <Ahref href="docsMetadata" value="Onchain Stored CBOR"/>
        <br />
        <Style.IconSquare /> <strong>Provenance</strong>: Parent-Child Inscription (
        <Ahref href="inscriptionParent" value="Parent"/>)
        <br />
        <Style.IconSquare /> <strong>Inscription Range</strong>: 57320047 to 57338706
        <br />
        <Style.IconSquare /> <strong>Metadata</strong>: <a href={fileUrl} download="inscriptions.json">Download JSON</a>
      </p>

      <Style.Divider />

      {
        <>
          <Style.MintButton href={links.magicEden} target="_blank">→ Collect on Magic Eden</Style.MintButton>
          {/*<p> ({" "} <Ahref href="magicEden" value="View Collection"/> {" "} ) </p>*/}
        </>
      }
    </>
  );
}
function Links() {

  return (
    <>
      <p>
        <p style={{ marginBottom: "0.5em" }}>
          <strong>Social</strong>
        </p>
        <Style.IconSquare />{" "}

        <Ahref href="twitter" value="Twitter"/>
        <br />
        <Style.IconSquare />{" "}
        <Ahref href="linktree" value="Portfolio"/>
      </p>

      <Style.Divider />

      <p>
        <p style={{ marginBottom: "0.5em" }}>
          <strong>Inscriptions</strong>
        </p>
        <Style.IconSquare />{" "}
        <Ahref href="inscriptionParent" value="Provenance: Collection Parent"/>
        <br />
        <Style.IconSquare />{" "}
        <Ahref href="inscriptionArtist" value="Provenance: Artist"/>
        <br />
        <Style.IconSquare />{" "}
        <Ahref href="inscriptionRenderer" value="Recursive Module: Render Engine"/>
        <br />
        <Style.IconSquare />{" "}
        <Ahref href="inscriptionStylesheet" value="Recursive Module: Stylesheet"/>
      </p>

      <Style.Divider />

      <p>
        <p style={{ marginBottom: "0.5em" }}>
          <strong>Storefronts</strong>
        </p>
        <Style.IconSquare />{" "}
        <Ahref href="magicEden" value="Magic Eden"/>
        <br />
        <Style.IconSquare />{" "}
        <Ahref href="gammaIO" value="Gamma.io"/>
        <br />
        <Style.IconSquare />{" "}
        <Ahref href="okx" value="OKX"/>
{/*
        <br />
        <Style.IconSquare />{" "}
        <Ahref href="ordinalsMarket" value="Ordinals Market"/>
        <br />
        <Style.IconSquare />{" "}
        <Ahref href="inscribeNow" value="InscribeNow"/>
        <br />
        <Style.IconSquare />{" "}
        <Ahref href="ordinalsWallet" value="Ordinals Wallet"/>
*/}
      </p>
    </>
  );
}

function Index() {
  return (
    <Style.Background>
      <Navbar />
      <Style.Container>
        <Style.ColumnLeft>

          <Style.HiddenMobile>
          {
            imageExamples.map((imgSrc, index)=>(
              <Style.WindowImage key={index} src={imgSrc} />
            ))
          }
          </Style.HiddenMobile>

           <Style.ViewOnlyMobile>
          {
            <>
            <Style.WindowImage src={imgExample29} />
            </>
          }
          </Style.ViewOnlyMobile>

        </Style.ColumnLeft>

        <Style.ColumnRight>
          {/*<Style.Window>
            <Style.WindowTitleBar>
              <Style.WindowTitle>MINT.exe</Style.WindowTitle>
              <Style.Rectangle />
            </Style.WindowTitleBar>

            <Style.WindowContent>
              <Mint/>
            </Style.WindowContent>
          </Style.Window>*/}

          <Style.Window>
            <Style.WindowTitleBar>
              <Style.WindowTitle>about.txt</Style.WindowTitle>
              <Style.Rectangle />
            </Style.WindowTitleBar>

            <Style.WindowContent >


              <p>
                <em><strong>Memory Blocks</strong></em> is an generative art collection
                that explores the remnants of our digital past, when early
                operating systems were a frontier of discovery and wonder.
              </p>
              <Style.Break/>
              <p>▣ <em>Genesis Ordinals art collection by <Ahref href={"twitter"} value={"dailofrog"}/></em></p>
               <Style.Divider />
               <Style.ViewCollectionDiv><Link to={`/collection/`}>→ View Collection</Link></Style.ViewCollectionDiv>
            </Style.WindowContent>
          </Style.Window>

          <Style.Window>
            <Style.WindowTitleBar>
              <Style.WindowTitle>mint.txt</Style.WindowTitle>
              <Style.Rectangle />
            </Style.WindowTitleBar>

            <Style.WindowContent>
              <MintInfo />
            </Style.WindowContent>
          </Style.Window>

          <Style.Window>
            <Style.WindowTitleBar>
              <Style.WindowTitle>statement.txt</Style.WindowTitle>
              <Style.Rectangle />
            </Style.WindowTitleBar>

            <Style.WindowContent>
              <Statement />
            </Style.WindowContent>
          </Style.Window>

          

          <Style.Window>
            <Style.WindowTitleBar>
              <Style.WindowTitle>links.txt</Style.WindowTitle>
              <Style.Rectangle />
            </Style.WindowTitleBar>

            <Style.WindowContent>
              <Links />
            </Style.WindowContent>
          </Style.Window>

          <FAQ />
        </Style.ColumnRight>
      </Style.Container>

      <Style.Footer>
        © 2024 Dailofrog Technologies. All Rights Reserved.
      </Style.Footer>
    </Style.Background>


  );
}

export default Index;
